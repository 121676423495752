import { GET, QUERY } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_property_dialog: false,
      transaction_billing: 0,
      transaction_supplier: 0,
      transaction_contact_person: 0,
      transaction_property: 0,
    };
  },
  methods: {
    createSupplier() {},
    createContactPerson() {},
    createSupplierProperty() {},
    getJobSuppliers(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "supplier-project-contract-list",
              data: { search: search },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getSuppliers(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "supplier-list", data: { search: search } })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getSupplierPersons(supplier, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (supplier <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "contact-person-list/" + supplier,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getSupplierProperties(supplier, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (supplier <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "property-list/" + supplier,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getSupplier() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "supplier/" + _this.supplier })
            .then((response) => {
              let billingAddress = this.getBillingAddress(response.data);
              this.transaction_billing = billingAddress.id;
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.masterDialogClose();
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(supplier) {
      let defaultPerson = {};
      const _this = this;
      if (supplier && _this.lodash.isEmpty(supplier.persons) === false) {
        for (let i = 0; i < supplier.persons.length; i++) {
          if (supplier.persons[i].default) {
            defaultPerson = _this.lodash.cloneDeep(supplier.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPersonById(supplier, id) {
      let defaultPerson = {};
      const _this = this;

      if (supplier && _this.lodash.isEmpty(supplier.persons) === false) {
        for (let i = supplier.persons.length - 1; i >= 0; i--) {
          if (supplier.persons[i].id == id) {
            defaultPerson = _this.lodash.cloneDeep(supplier.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPropertyById(supplier, id) {
      let property = {};
      const _this = this;
      if (supplier && _this.lodash.isEmpty(supplier.address) === false) {
        for (let i = supplier.address.length - 1; i >= 0; i--) {
          if (supplier.address[i].id == id) {
            property = _this.lodash.cloneDeep(supplier.address[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(property);
    },
    getBillingAddress(supplier) {
      let billing = {};
      const _this = this;
      if (supplier && _this.lodash.isEmpty(supplier.address) === false) {
        for (let i = supplier.address.length - 1; i >= 0; i--) {
          if (supplier.address[i].type == 2) {
            billing = _this.lodash.cloneDeep(supplier.address[i]);
            break;
          }
        }
      }
      return _this.lodash.cloneDeep(billing);
    },
    getEmailDetails(supplier) {
      let emailArr = [];
      const _this = this;
      if (supplier && _this.lodash.isEmpty(supplier.details) === false) {
        for (let i = 0; i < supplier.details.length; i++) {
          if (supplier.details[i].type === 1) {
            emailArr.push(supplier.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(emailArr);
    },
    getPhoneDetails(supplier) {
      let phoneArr = [];
      const _this = this;
      if (supplier && _this.lodash.isEmpty(supplier.details) === false) {
        for (let i = 0; i < supplier.details.length; i++) {
          if (supplier.details[i].type === 2) {
            phoneArr.push(supplier.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(phoneArr);
    },
    resetAll() {
      this.transaction_supplier = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.transaction_contact_person_dialog = false;
      this.transaction_property_dialog = false;
    },
    selectSupplierPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      if (this.transaction_property <= 0) {
        this.transaction_property_dialog = true;
      }
      this.checkJob();
    },
    selectSupplierProperty(property) {
      this.transaction_property = this.lodash.toSafeInteger(property);
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      let supplier = _this.lodash.toSafeInteger(_this.transaction_supplier);
      let contact_person = _this.lodash.toSafeInteger(
        _this.transaction_contact_person
      );
      let property = _this.lodash.toSafeInteger(_this.transaction_property);
      let billing = _this.lodash.toSafeInteger(_this.transaction_billing);

      if (_this.transaction_type === "quotation") {
        if (supplier > 0 && contact_person > 0 && billing > 0) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                supplier: supplier,
                contact_person: contact_person,
                billing: billing,
              },
            })
          );
        }
      } else {
        if (supplier > 0 && contact_person > 0 && property > 0) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transaction_type + ".create", {
              query: {
                supplier,
                contact_person,
                property,
              },
            })
          );
        }
      }
    },
    createTransaction(supplier, param) {
      const _this = this;
      _this.transaction_supplier = _this.lodash.toSafeInteger(supplier.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (_this.transaction_type === "property") {
        _this.$router.push(
          _this.getDefaultRoute("property.create", {
            query: { supplier: _this.transaction_supplier },
          })
        );
      } else {
        if (supplier.persons_count > 1) {
          _this.transaction_contact_person_dialog = true;
        } else if (supplier.property_count > 1) {
          _this.transaction_property_dialog = true;
        }

        if (supplier.persons_count == 1) {
          let firstPerson = _this.lodash.head(supplier.persons);
          _this.transaction_contact_person = firstPerson.id;
        }

        if (supplier.property_count == 1) {
          let firstProperty = _this.lodash.head(supplier.property);
          _this.transaction_property = firstProperty.id;
        }

        _this.checkJob();
      }
    },
  },
};
